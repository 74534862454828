import axios from 'axios'
import router from '@/router'

const actions = {
  async fetchLead ({ state, commit }) {
    const api = process.env.VUE_APP_API_CSAT
    const { idLeadHistory } = router.currentRoute.params

    if (!idLeadHistory) {
      return
    }

    const url = `${api}/${idLeadHistory}`

    commit('SET_IS_LOADING', true)

    await axios
      .get(url)
      .then(({ data }) => {
        if (!data.is_bot) {
          const attendantFirstName = data.attendant_name.replace(/^(.+?)\s.+$/, '$1')

          data.attendant_name = attendantFirstName
        }

        commit('SET_LEAD', data)
      })
      .catch(error => {
        throw error
      })
      .then(() => {
        commit('SET_IS_LOADING', false)
      })
  },

  async sendEvaluation ({ state, commit }, data) {
    const api = process.env.VUE_APP_API_CSAT
    const { idLeadHistory } = router.currentRoute.params
    const url = `${api}/${idLeadHistory}`

    await axios
      .post(url, data)
      .then(() => {
        const lead = {
          ...state.lead,
          ...data
        }

        commit('SET_LEAD', lead)
      })
      .catch(error => {
        throw error
      })
  }
}

export default actions

<template>
    <header class="base-header" :class="headerClass" :style="headerStyle">
        <div class="base-header__container">
            <img :src="attendantPhoto"
                onerror="this.src='https://weclever-public.s3.amazonaws.com/dashboard/avatar/default.png'"
                alt="Attendant photo" class="base-header__attendant-photo">

            <img :src="platformLogo"
                onerror="this.src='https://weclever-public.s3.amazonaws.com/dashboard/platform-logos/default.png'"
                alt="Platform logo" class="base-header__platform-logo">

            <div class="base-header__text" :class="{ 'base-header__text--only-child': !platformLogo }">
                Atendimento finalizado em: <strong>{{ orderDate }}</strong>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseHeader',

  computed: {
    ...mapGetters('global', [
      'wasSent',
      'color',
      'attendantPhoto',
      'platformLogo',
      'orderDate',
      'isBot'
    ]),

    ...mapGetters('platform', ['colorPrimary']),

    headerClass () {
      return {
        'base-header--platform-only': this.wasSent,
        'base-header--bot': this.isBot
      }
    },

    headerStyle () {
      return `background-color: ${this.color}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-header {
    align-self: start;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 0 0 30px 30px;
    background-color: map-get($colors-util, 'light');

    &--bot & {
      &__container {
        display: flex;
        height: 116px;
        column-gap: 10px;
        align-items: center;
      }

      &__platform-logo {
        flex-grow: 0;
        width: 130px;
        max-width: 100%;
        max-height: 100%;
        margin-left: 25px;
      }
    }

    &--platform-only & {
      &__container {
        display: flex;
        height: 116px;
        justify-content: center;
        align-items: center;
      }

      &__attendant-photo,
      &__text {
        display: none;
      }

      &__platform-logo {
        $size-max: 35%;

        max: {
          width: $size-max;
          height: $size-max;
        }
      }
    }

    &__container {
      display: grid;
      max-height: 116px;
      grid-template: {
        columns: 116px auto;
        rows: 76px 40px;
      }
      column-gap: 30px;
      border-radius: 30px;
      background: rgba(0, 0, 0, 0.2);
    }

    &__attendant-photo {
      grid-row: 1/3;
      width: 116px;
      height: 116px;
      object-fit: cover;
      border-radius: 30px;
      background: rgba(0, 0, 0, 0.2);
    }

    &__platform-logo {
      $size-max: 70%;

      max: {
        width: $size-max;
        height: $size-max;
      }
      align-self: center;
    }

    &__text {
      padding-left: 5px;
      color: #fff;
      font-size: 0.75rem;
      line-height: 140%;

      &--only-child {
        margin-top: 45px;
      }

      strong {
        display: block;
        font: {
          weight: 800;
          size: 14px;
        }
      }
    }
  }
</style>

<template>
  <section class="evaluation-message">
    <div class="evaluation-message__heading">
      Agradecemos muito!
    </div>

    <IconHandHeart class="evaluation-message__image" />

    <div
      class="evaluation-message__text"
      v-html="text"
    />
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'EvaluationMessage',

  computed: {
    ...mapState('global', {
      evaluation: state => state.lead.evaluation
    }),

    ...mapGetters('global', ['ratingData']),

    text () {
      return this.ratingData(this.evaluation)?.message
    }
  }
}
</script>

<style lang="scss" scoped>
  .evaluation-message {
    padding: 40px;
    border: 3px solid map-get($colors-util, 'lighter');
    border-radius: 30px;
    line-height: 130%;
    text-align: center;

    &__heading {
      margin-bottom: 40px;
      font-size: 1.35rem;
    }

    &__image {
      margin-bottom: 40px;
    }

    &__text {
      font-size: 1.05rem;
    }
  }
</style>

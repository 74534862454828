import { render, staticRenderFns } from "./app.vue?vue&type=template&id=6b47c059&"
var script = {}
import style0 from "./app.vue?vue&type=style&index=0&id=6b47c059&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
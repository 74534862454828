const getters = {
  wasSent (state) {
    return Boolean(state.lead.evaluation)
  },

  color (state) {
    return state.lead.color
  },

  attendantPhoto (state) {
    try {
      return `https://weclever-public.s3.amazonaws.com/dashboard/avatar/production/${state.lead.attendant_id}.jpg`
    } catch (error) {
      console.warn('user avatar not found.', error)
    }
  },

  platformLogo (state) {
    try {
      return `https://weclever-public.s3.amazonaws.com/dashboard/platform-logos/${state.lead.platform_name}.png`
    } catch (error) {
      console.warn('Platform logo not found.', error)
    }
  },

  orderDate (state) {
    const { order_date: orderDate } = state.lead

    if (!orderDate) {
      return ''
    }

    return new Date(orderDate).toLocaleDateString('pt-BR')
  },

  ratingData: (state) => (evaluation) => {
    return state.rating.find((item) => item.evaluation === evaluation)
  },

  isBot: (state) => {
    return Boolean(state.lead?.is_bot)
  }
}

export default getters

const state = {
  platforms: [
    {
      id: 612,
      colorPrimary: '#c81a78'
    },
    {
      id: 609,
      colorPrimary: '#04e7d3'
    },
    {
      id: 610,
      colorPrimary: '#000000'
    },
    {
      id: 613,
      colorPrimary: '#cc6e24'
    },
    {
      id: 605,
      colorPrimary: '#ff6039'
    },
    {
      id: 3,
      colorPrimary: '#ff8956'
    },
    {
      id: 615,
      colorPrimary: '#2eaaba'
    },
    {
      id: 602,
      colorPrimary: '#009bbf'
    },
    {
      id: 616,
      colorPrimary: '#182e3f'
    },
    {
      id: 617,
      colorPrimary: '#ff007b'
    },
    {
      id: 618,
      colorPrimary: '#0055f1'
    },
    {
      id: 619,
      colorPrimary: '#ffee19'
    },
    {
      id: 620,
      colorPrimary: '#1f8693'
    },
    {
      id: 621,
      colorPrimary: '#ff6910'
    },
    {
      id: 622,
      colorPrimary: '#3b3b3b'
    },
    {
      id: 625,
      colorPrimary: '#003f57'
    },
    {
      id: 627,
      colorPrimary: '#9b111e'
    },
    {
      id: 628,
      colorPrimary: '#000000'
    },
    {
      id: 629,
      colorPrimary: '#e9c3b0'
    },
    {
      id: 630,
      colorPrimary: '#4e306e'
    },
    {
      id: 631,
      colorPrimary: '#086568'
    },
    {
      id: 632,
      colorPrimary: '#00463c'
    },
    {
      id: 633,
      colorPrimary: '#3d8c4c'
    },
    {
      id: 635,
      colorPrimary: '#22773b'
    },
    {
      id: 636,
      colorPrimary: '#7a6c54'
    },
    {
      id: 645,
      colorPrimary: '#000000'
    },
    {
      id: 644,
      colorPrimary: '#0c3723'
    },
    {
      id: 646,
      colorPrimary: '#034168'
    },
    {
      id: 647,
      colorPrimary: '#334a7c'
    },
    {
      id: 650,
      colorPrimary: '#02933f'
    },
    {
      id: 649,
      colorPrimary: '#1e2a54'
    },
    {
      id: 651,
      colorPrimary: '#7668ac'
    },
    {
      id: 652,
      colorPrimary: '#f28c2a'
    },
    {
      id: 655,
      colorPrimary: '#c22629'
    },
    {
      id: 657,
      colorPrimary: '#00e88f'
    },
    {
      id: 658,
      colorPrimary: '#cd1719'
    },
    {
      id: 659,
      colorPrimary: '#28388f'
    },
    {
      id: 660,
      colorPrimary: '#fa6300'
    },
    {
      id: 662,
      colorPrimary: '#343434'
    },
    {
      id: 665,
      colorPrimary: '#e30609'
    },
    {
      id: 667,
      colorPrimary: '#00c5ff'
    },
    {
      id: 648,
      colorPrimary: '#c81a78'
    },
    {
      id: 668,
      colorPrimary: '#000000'
    },
    {
      id: 671,
      colorPrimary: '#1b234a'
    },
    {
      id: 672,
      colorPrimary: '#0a83a0'
    },
    {
      id: 614,
      colorPrimary: '#1001f5'
    },
    {
      id: 669,
      colorPrimary: '#3c3c3c'
    },
    {
      id: 670,
      colorPrimary: '#c0beb5'
    },
    {
      id: 676,
      colorPrimary: '#4671dd'
    },
    {
      id: 673,
      colorPrimary: '#0074bb'
    },
    {
      id: 673,
      colorPrimary: '#0074bb'
    },
    {
      id: 677,
      colorPrimary: '#000e2b'
    },
    {
      id: 678,
      colorPrimary: '#f37248'
    },
    {
      id: 661,
      colorPrimary: '#6906b7'
    },
    {
      id: 679,
      colorPrimary: '#04397b'
    },
    {
      id: 684,
      colorPrimary: '#142364'
    },
    {
      id: 685,
      colorPrimary: '#6906b7'
    },
    {
      id: 708,
      colorPrimary: '#ff151e'
    },
    {
      id: 711,
      colorPrimary: '#0052cc'
    },
    {
      id: 683,
      colorPrimary: '#4e008e'
    },
    {
      id: 706,
      colorPrimary: '#334acd'
    },
    {
      id: 714,
      colorPrimary: '#00a0ff'
    },
    {
      id: 638,
      colorPrimary: '#0027f4'
    },
    {
      id: 656,
      colorPrimary: '#0f92ff'
    },
    {
      id: 686,
      colorPrimary: '#03a9f4'
    },
    {
      id: 687,
      colorPrimary: '#ffb000'
    },
    {
      id: 688,
      colorPrimary: '#c81a78'
    },
    {
      id: 689,
      colorPrimary: '#0ada52'
    },
    {
      id: 694,
      colorPrimary: '#4b73da'
    },
    {
      id: 698,
      colorPrimary: '#4b73da'
    },
    {
      id: 699,
      colorPrimary: '#0b48fe'
    },
    {
      id: 700,
      colorPrimary: '#4e2096'
    },
    {
      id: 703,
      colorPrimary: '#0a52f8'
    },
    {
      id: 704,
      colorPrimary: '#d2541f'
    },
    {
      id: 707,
      colorPrimary: '#8cad34'
    },
    {
      id: 709,
      colorPrimary: '#660099'
    },
    {
      id: 713,
      colorPrimary: '#0052cc'
    },
    {
      id: 715,
      colorPrimary: '#34d399'
    },
    {
      id: 717,
      colorPrimary: '#0027f4'
    },
    {
      id: 718,
      colorPrimary: '#ffd400'
    },
    {
      id: 720,
      colorPrimary: '#4b73da'
    },
    {
      id: 721,
      colorPrimary: '#ec0000'
    },
    {
      id: 722,
      colorPrimary: '#ed0059'
    },
    {
      id: 725,
      colorPrimary: '#0F92FF'
    },
    {
      id: 727,
      colorPrimary: '#E62936'
    },
    {
      id: 728,
      colorPrimary: '#E40D5B'
    },
    {
      id: 729,
      colorPrimary: '#1FAD2F'
    },
    {
      id: 732,
      colorPrimary: '#0E6209'
    },
    {
      id: 731,
      colorPrimary: '#4b73da'
    },
    {
      id: 730,
      colorPrimary: '#fed800'
    },
    {
      id: 705,
      colorPrimary: '#172542'
    },
    {
      id: 840,
      colorPrimary: '#172542'
    }
  ]
}

export default state

<template>
  <div class="home-view">
    <SkeletonHome v-if="isLoading"/>

    <template v-else>
      <BaseHeader/>

      <main
        class="home-view__main"
        :class="mainClass"
      >
        <Transition name="transition-1">
          <Component :is="componentActive"/>
        </Transition>
      </main>
    </template>

    <BaseFooter/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'HomeView',

  computed: {
    ...mapState('global', ['isLoading']),
    ...mapGetters('global', ['wasSent']),

    componentActive () {
      return this.wasSent
        ? 'EvaluationMessage'
        : 'EvaluationForm'
    },

    mainClass () {
      return {
        'home-view__main--centered': this.componentActive === 'EvaluationMessage'
      }
    }
  },

  methods: {
    ...mapActions('global', ['fetchLead'])
  },

  mounted () {
    this.fetchLead()
  }
}
</script>

<style lang="scss" scoped>
  .home-view {
    display: grid;
    max-width: map-get($breakpoints, 'medium');
    min-height: 100vh;
    grid-template-rows: auto 1fr auto;
    margin: 0 auto;

    &__loader {
      padding: 20px;
      font-family: $font-system;
      text-align: center;
    }

    &__main {
      padding: 0 30px;

      &--centered {
        align-self: center;
      }
    }
  }
</style>

const getters = {
  colorPrimary (state, getters, rootState) {
    const { id_platform: platformId } = rootState.global.lead
    const platform = state.platforms.find(platform => platform.id === platformId)

    return platform
      ? platform.colorPrimary
      : '#aaaaaa'
  }
}

export default getters

import {
  SET_IS_LOADING,
  SET_LEAD
} from './mutation-types'

const mutations = {
  [SET_IS_LOADING] (state, isLoading) {
    state.isLoading = isLoading
  },

  [SET_LEAD] (state, lead) {
    state.lead = lead
  }

}

export default mutations

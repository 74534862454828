
import homeView from '@/views/HomeView'

const routes = [
  {
    path: '/:idLeadHistory',
    name: 'home',
    component: homeView
  }
]

export default routes

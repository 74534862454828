<template>
  <svg
    width="46"
    height="43"
    viewBox="0 0 46 43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M23.9825 0.582033C23.7904 0.223938 23.4125 0 23.0006 0C22.5886 0 22.2108 0.223938 22.0187 0.582033L15.5302 12.7524C15.3697 13.0524 15.0753 13.2629 14.7327 13.3199L0.922306 15.6106C0.516663 15.6775 0.181828 15.9614 0.0542793 16.3453C-0.0732765 16.7296 0.0279541 17.152 0.316059 17.4407L10.115 27.2519C10.3575 27.4947 10.4702 27.8343 10.4194 28.1713L8.37369 41.7554C8.31437 42.1556 8.48493 42.5545 8.81827 42.7926C9.15199 43.0304 9.59286 43.0664 9.96219 42.8868L22.5072 36.7814C22.8183 36.6301 23.1821 36.6301 23.4928 36.7814L36.0378 42.8868C36.4068 43.0664 36.848 43.0304 37.1814 42.7926C37.5151 42.5545 37.6856 42.1557 37.6263 41.7554L35.5802 28.1713C35.5298 27.8343 35.6422 27.4947 35.885 27.2519L45.6839 17.4407C45.972 17.1521 46.0733 16.7296 45.9457 16.3453C45.8178 15.9614 45.483 15.6775 45.0773 15.6106L31.267 13.3199C30.9247 13.2629 30.6303 13.0524 30.4698 12.7524L23.9825 0.582033Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconStar'
}
</script>

<template>
  <form
    class="evaluation-form"
    @submit.prevent="formSubmit"
  >
    <div class="evaluation-form__rating">
      <div
        class="evaluation-form__rating__question"
        v-html="ratingQuestion"
      />

      <div class="evaluation-form__rating__stars">
        <label
          v-for="i in 5"
          :key="i"
        >
          <input
            type="radio"
            name="evaluation"
            :value="i"
            v-model="evaluation"
            :disabled="isLoading"
          >

          <IconStar :fill="ratingStarsFillColor(i)"/>
        </label>
      </div>

      <div class="evaluation-form__rating__status">
        <div
          class="evaluation-form__rating__status__empty-state"
          v-if="!evaluation"
        >
          <span>Muito<br>insatisfeito(a)</span>
          <hr>
          <span>Muito<br>satisfeito(a)</span>
        </div>

        <div
          class="evaluation-form__rating__status__text"
          :style="ratingStatusTextStyle"
          v-else
          v-html="ratingStatus"
        />
      </div>
    </div>

    <div class="evaluation-form__details">
      <p v-html="detailsQuestion"/>

      <textarea
        :maxlength="detailsMaxLength"
        placeholder="Deixe seu comentário"
        v-model="details"
        :disabled="isLoading"
      />
    </div>

    <button
      class="evaluation-form__button"
      :style="buttonStyle"
      :disabled="!evaluation"
    >
      <span v-show="!isLoading">Enviar</span>
      <IconLoader v-show="isLoading"/>
    </button>
  </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'EvaluationForm',

  data () {
    return {
      isLoading: false,
      evaluation: null,
      details: '',
      detailsMaxLength: 200
    }
  },

  computed: {
    ...mapState('global', ['lead']),
    ...mapGetters('global', ['ratingData']),
    ...mapGetters('global', ['color']),
    ...mapGetters('platform', ['colorPrimary']),

    ratingQuestion () {
      const { attendant_name: attendantName } = this.lead

      return attendantName
        ? `Quanto ao atendimento feito por <strong>${attendantName}</strong>, qual seu nível de satisfação?`
        : 'Quanto a este atendimento, qual seu nível de satisfação?'
    },

    detailsQuestion () {
      const detailsQuestion = this.ratingData(this.evaluation)?.detailsQuestion
      const defaultQuestion = 'Algo te marcou neste atendimento? Compartilhe conosco!'

      return detailsQuestion || defaultQuestion
    },

    ratingStatus () {
      return this.ratingData(this.evaluation)?.status
    },

    ratingStatusTextStyle () {
      return `color: ${this.color}`
    },

    buttonStyle () {
      return `background-color: ${this.color}`
    }
  },

  methods: {
    ...mapActions('global', ['sendEvaluation']),

    async formSubmit () {
      const { evaluation, details } = this
      const data = { evaluation, details }

      this.isLoading = true

      await this.sendEvaluation(data)

      this.isLoading = false
    },

    ratingStarsFillColor (evaluation) {
      const colorDefault = '#e2e4e9'

      return evaluation <= this.evaluation
        ? this.color
        : colorDefault
    }
  }
}
</script>

<style lang="scss" scoped>
  .evaluation-form {
    &__rating {
      &__question {
        font-size: 1.05rem;
        line-height: 120%;
        text-align: center;
      }

      &__stars {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        label {
          @extend %select-off;

          position: relative;
          display: inline-block;
          padding: 0 10px;
          cursor: pointer;

          input {
            visibility: hidden;
            height: 0;
            position: absolute;
            top: 0;
            left: 0;
          }

          svg {
            width: 46px;
            transition: all 0.05s;
          }
        }
      }

      &__status {
        display: flex;
        max-width: 350px;
        height: 30px;
        align-items: center;
        margin: 20px auto;
        text-align: center;

        &__empty-state {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 0.75rem;
          }

          hr {
            width: 30%;
            border: 0;
            border-top: 2px solid map-get($colors-util, 'lighter');
          }
        }

        &__text {
          flex-grow: 1;
          font-size: 1.1rem;
        }
      }
    }

    &__details {
      margin-top: 30px;

      p {
        margin-bottom: 20px;
        font-size: 1.05rem;
        line-height: 120%;
        text-align: center;
      }

      textarea {
        display: block;
        width: 100%;
        height: 111px;
        padding: 20px;
        border: 2px solid map-get($colors-util, 'lighter');
        border-radius: 30px;
        color: nth($colors-custom, 1);
        font: {
          size: 0.9rem;
          family: $font-default;
        }

        &:focus {
          border-color: nth($colors-util, 1);
        }
      }
    }

    &__button {
      @extend %select-off;

      display: flex;
      width: 100%;
      height: 55px;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      border: 0;
      border-radius: 30px;
      transition: all 0.1s;
      background-color: map-get($colors-util, 'light');
      color: #fff;
      font: {
        size: 1.1rem;
        weight: 700;
      }

      &:disabled {
        @extend %disabled;
      }

      svg {
        width: 60px;
      }
    }
  }
</style>

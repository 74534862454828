const state = {
  isLoading: false,
  lead: {},
  rating: [
    {
      evaluation: 1,
      status: 'Muito insatisfeito(a)',
      detailsQuestion: 'Conte mais detalhes. Queremos entender como melhorar.',
      message: 'Esta não é a experiência que gostaríamos de proporcionar, mas sua resposta vai nos ajudar a melhorar.'
    },
    {
      evaluation: 2,
      status: 'Insatisfeito(a)',
      detailsQuestion: 'Conte mais detalhes. Queremos entender como melhorar.',
      message: 'Esta não é a experiência que gostaríamos de proporcionar, mas sua resposta vai nos ajudar a melhorar.'
    },
    {
      evaluation: 3,
      status: 'Neutro',
      detailsQuestion: 'Algo te marcou neste atendimento? Compartilhe conosco!',
      message: 'Gostaríamos que sua experiência conosco fosse incrível. Sua resposta vai nos ajudar a melhorar.'
    },
    {
      evaluation: 4,
      status: 'Satisfeito(a)',
      detailsQuestion: 'Que bom que gostou! Conte pra gente o que mais te marcou no atendimento.',
      message: 'Ficamos felizes em proporcionar esta experiência.'
    },
    {
      evaluation: 5,
      status: 'Muito satisfeito(a)',
      detailsQuestion: 'Que bom que gostou! Conte pra gente o que mais te marcou no atendimento.',
      message: 'Ficamos felizes em proporcionar esta experiência.'
    }
  ]
}

export default state

<template>
  <footer class="base-footer">
    <img
      src="@/assets/images/powered.svg"
      alt="Powered by WeClever"
      class="base-footer__powered"
    >
  </footer>
</template>

<script>
export default {
  name: 'BaseFooter'
}
</script>

<style lang="scss" scoped>
  .base-footer {
    align-self: end;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px 30px 0 0;
    background: map-get($colors-util, 'lightest');

    &__powered {
      display: block;
      margin: 0 auto;
    }
  }
</style>

<template>
  <div class="skeleton-home">
    <div class="skeleton-home__header"></div>

    <div class="skeleton-home__main">
      <div class="skeleton-home__rating">
        <div class="skeleton-home__rating__question"></div>
        <div class="skeleton-home__rating__stars"></div>
        <div class="skeleton-home__rating__status"></div>
      </div>

      <div class="skeleton-home__details"></div>
      <div class="skeleton-home__button"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonHome'
}
</script>

<style lang="scss" scoped>
.skeleton-home {
  &__header {
    @extend %skeleton;

    height: 156px;
    border-radius: 0 0 30px 30px;
    margin-bottom: 30px;
  }

  &__main {
    padding: 0 30px;
  }

  &__rating {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__question {
      @extend %skeleton;

      width: 100%;
      height: 40px;
    }

    &__stars {
      @extend %skeleton;

      width: 350px;
      height: 46px;
      margin-top: 20px;
    }

    &__status {
      @extend %skeleton;

      width: 350px;
      height: 30px;
      margin-top: 20px;
    }
  }

  &__details {
    @extend %skeleton;

    height: 150px;
    margin-top: 30px;
  }

  &__button {
    @extend %skeleton;

    height: 55px;
    margin-top: 30px;
    border-radius: 30px;
  }
}
</style>
